<script>
import AllNotificationComponent from "@/views/components/AllNotificationComponent";

export default {
  name: 'EmptyBase',

  components: {
    AllNotificationComponent
  }
}
</script>

<template>
  <v-app>
    <router-view></router-view>
    <all-notification-component></all-notification-component>
  </v-app>
</template>